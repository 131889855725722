import { configureStore } from "@reduxjs/toolkit";
import {appInfoSlice} from "./app-data-slice";


export const makeStore = () =>
    configureStore({
        reducer: {
            [appInfoSlice.name]: appInfoSlice.reducer
        },
        devTools: true,
    });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;