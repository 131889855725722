import {AccountAppState} from "../../types/account-app-state";
import {preloadedUser} from "@siser/siser-common/model/User";
import {createSlice} from "@reduxjs/toolkit";
import {ProductRegistration} from '@siser/siser-common/types/product-registration';
import {User} from "@siser/siser-common/types/user";
import {AppState} from "./store";
import {ProductModelInfo, SUPPORTED_MODEL_NUMBER} from "@siser/siser-common/types/product-model-info";
import {modelsInfo} from "@siser/siser-common/model/Cutter.js";

const initialState: AccountAppState = {
    modelsInfo: modelsInfo,
    openModal: false,
    previousRegistrations: [],
    user: preloadedUser()
}

const SLICE_NAME = 'app_info';

// Actual Slice
export const appInfoSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        dispatchRegistrationHistory(state, action: { payload: ProductRegistration[] }){
            state.previousRegistrations = action.payload;
        },
        dispatchToggleLoginModal(state, action: { payload: boolean }) {
            state.openModal = action.payload;
        },
        dispatchUser(state, action: { payload: User }){
            state.user = action.payload;
        }
    }
});

export const {
    dispatchRegistrationHistory,
    dispatchToggleLoginModal,
    dispatchUser
} = appInfoSlice.actions;

export const selectModelsInfo = (state: AppState): { [k in SUPPORTED_MODEL_NUMBER]: ProductModelInfo } => {
    return state[appInfoSlice.name].modelsInfo;
}

export const selectOpenModal = (state: AppState): boolean => {
    return state[appInfoSlice.name].openModal;
}

export const selectPreviousRegistrations = (state: AppState): ProductRegistration[] => {
    return state[appInfoSlice.name].previousRegistrations;
}


export const selectUser = (state: AppState): User => {
    return state[appInfoSlice.name].user;
}

